import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type OrderlineTimelineStep,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import {
  type GetOrderlineStateEnum,
  ORDERLINE_STATE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'

type getActiveTimelineStepReturnValue = StepType | ''

export function getActiveTimelineStep({
  orderlineState,
  orderlineTimeline,
}: {
  orderlineState: GetOrderlineStateEnum
  orderlineTimeline: Array<OrderlineTimelineStep>
}): getActiveTimelineStepReturnValue {
  const activeStep = orderlineTimeline.find((item) => item.current)

  if (
    activeStep?.type === ORDERLINE_TIMELINE_STEP_TYPE.validation &&
    orderlineState === ORDERLINE_STATE_ENUM.shipped
  ) {
    return ORDERLINE_TIMELINE_STEP_TYPE.shipment
  }

  return activeStep?.type ? activeStep.type : ''
}
