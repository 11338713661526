<template>
  <RevButton
    v-if="!tiny"
    data-qa="print-return-label"
    data-test="print-return-label"
    full-width="adaptive"
    target="_blank"
    :to="shipmentLabelUrl"
    :variant
  >
    {{ i18n(translations.label) }}
  </RevButton>

  <RevButton
    v-else
    class="w-fit"
    data-qa="print-return-label"
    data-test="print-return-label"
    :icon="IconDownload"
    size="small"
    target="_blank"
    :to="shipmentLabelUrl"
    :variant
  >
    {{ i18n(translations.label) }}
  </RevButton>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { IconDownload } from '@ds/icons/IconDownload'

import translations from './PrintLabelAction.translations'
import type { PrintLabelActionProps } from './PrintLabelAction.types'

const {
  shipmentLabelUrl,
  tiny = false,
  variant = 'secondary',
} = defineProps<PrintLabelActionProps>()

const i18n = useI18n()
</script>
