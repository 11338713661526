export default {
  content: {
    id: 'resolution_flow_return_confirmation_page_info_block_content',
    defaultMessage:
      "You must sign out of your accounts, remove your data, settings, and eSIM or the seller won't be able to work on your device.",
  },
  cta: {
    id: 'resolution_flow_return_confirmation_page_info_block_cta',
    defaultMessage: 'Learn more',
  },
  title: {
    id: 'dashboard_diagnosis_summary_know_before_you_ship',
    defaultMessage: 'Know before you ship!',
  },
}
