<template>
  <RevButton
    data-qa="get-more-help"
    data-test="get-more-help"
    full-width="adaptive"
    :to="conversationRoute"
    :variant
  >
    {{ i18n(translations.label) }}
  </RevButton>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'

import { CUSTOMER_CONVERSATION } from '~/scopes/customer-conversation/route-names'

import translations from './GetMoreHelpAction.translations'
import type { GetMoreHelpActionProps } from './GetMoreHelpAction.types'

const { resolutionId, variant = 'secondary' } =
  defineProps<GetMoreHelpActionProps>()

const i18n = useI18n()
const injectLocale = useRouteLocationWithLocale()

const conversationRoute = toNuxtLink(
  injectLocale({
    type: 'internal',
    name: CUSTOMER_CONVERSATION.RESOLUTION,
    params: {
      resolutionId,
    },
  }),
)
</script>
