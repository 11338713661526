export default {
  jobDoneStep: {
    id: 'resolution_flow_return_confirmation_page_step_job_done',
    defaultMessage:
      'Your work here is done! Once sent, we get the same updates you do, so no need to reach out.',
  },
  packingStepContent1: {
    id: 'resolution_flow_return_confirmation_page_step_pack_item_content_1',
    defaultMessage:
      "Pack your item, its accessories (unless you're getting a repair, then don't include accessories), and the documents listed in step 1.",
  },
  packingStepContent2: {
    id: 'resolution_flow_return_confirmation_page_step_pack_item_content_2',
    defaultMessage:
      "Make sure your item is secured in the box so it can't move around in transit.",
  },
  printSetp: {
    id: 'resolution_flow_return_confirmation_page_step_print',
    defaultMessage:
      'Print the return label and proof of purchase (if applicable) above.',
  },
  sendStep: {
    id: 'resolution_flow_return_confirmation_page_step_send_item_new_format',
    defaultMessage:
      'Send it off with the shipping provider indicated on your return label before {date}.',
  },
  sendStepCta: {
    id: 'resolution_flow_return_confirmation_page_step_send_item_link',
    defaultMessage: 'Learn more about making returns',
  },
  stickStep: {
    id: 'resolution_flow_return_confirmation_page_step_stick_return_label',
    defaultMessage:
      'Stick the return label to the top of the box and seal it tightly with strong tape.',
  },
  viewThisInfoAnytime: {
    id: 'resolution_flow_return_confirmation_page_footer_cta',
    defaultMessage: 'See this info anytime in My orders',
  },
}
