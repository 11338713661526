export default {
  footerCta: {
    id: 'resolution_flow_return_confirmation_page_footer_cta',
    defaultMessage: 'See this info anytime in Orders',
  },
  propTipInstructions: {
    id: 'resolution_flow_return_confirmation_page_section_pro_tip',
    defaultMessage:
      'Pro tip: Don’t open the package when you receive it. No repacking means less hassle for you.',
  },
  shipDeviceInstructions: {
    id: 'resolution_flow_return_confirmation_page_section_how_to_ship_a_device',
    defaultMessage: 'How to ship your device',
  },
  returnInstructions: {
    id: 'resolution_flow_return_confirmation_page_section_return_instructions',
    defaultMessage: 'Return instructions',
  },

  myOrdersCta: {
    id: 'resolution_flow_return_confirmation_page_cta_my_orders',
    defaultMessage: 'My orders',
  },
}
