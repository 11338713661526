<template>
  <RevInfoBlock
    class="mb-32 lg:mb-0"
    :icon="IconLockUnlocked"
    :title="i18n(translations.title)"
    variant="warning"
  >
    <p class="text-static-default-mid body-2">
      {{ i18n(translations.content) }}
    </p>

    <RevButton size="small" :to="aboutUserAccountArticleLink" variant="primary">
      {{ i18n(translations.cta) }}
    </RevButton>
  </RevInfoBlock>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconLockUnlocked } from '@ds/icons/IconLockUnlocked'

import { isConfigDefined } from '~/scopes/care-commons/utils/isConfigDefined'

import translations from './ReturnReminder.translations'

const i18n = useI18n()

const { ZENDESK_ARTICLE_ABOUT_USER_ACCOUNT_ID } = useRuntimeConfig().public

const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const aboutUserAccountArticleLink = toNuxtLink({
  type: 'external',
  href: `${zendeskFaqBaseUrl}/articles/${isConfigDefined(ZENDESK_ARTICLE_ABOUT_USER_ACCOUNT_ID, 'ZENDESK_ARTICLE_ABOUT_USER_ACCOUNT_ID')}`,
})
</script>
