export default {
  heading: {
    id: 'resolution_flow_return_confirmation_page_title_refund',
    defaultMessage: 'All set for your refund',
  },
  variantHeading: {
    id: 'resolution_flow_return_confirmation_page_shipped_variant_title_refund',
    defaultMessage: 'Let’s get you refunded',
  },

  subHeading: {
    id: 'resolution_flow_return_confirmation_page_info_title_refund_requested',
    defaultMessage: 'Refund requested',
  },

  description: {
    id: 'resolution_flow_return_confirmation_page_delivered_variant_refund_description',
    defaultMessage:
      'Once the seller receives your item, they’ll refund it within 3 business days and then send it back to you. It may take a few more days to arrive.',
  },
  pageTitle: {
    id: 'resolution_flow_return_confirmation_page_breadcrumb_refund_request',
    defaultMessage: 'Refund request',
  },
  variantDescription: {
    id: 'resolution_flow_return_confirmation_page_shipped_variant_description',
    defaultMessage:
      'It looks like the package is already on its way to you. Just sit tight until it arrives, then follow our simple return instructions.',
  },
  variantPreparedSubDescription: {
    id: 'resolution_flow_return_confirmation_page_prepared_variant_refund_subdescription',
    defaultMessage:
      'When the seller gets the item back, they’ll refund you within 3 business days of receiving it if you’ve already been charged.',
  },
  variantShippedSubDescription: {
    id: 'resolution_flow_return_confirmation_page_shipped_variant_refund_subdescription',
    defaultMessage:
      'Once the seller receives your item, they’ll refund you within 2 business days.',
  },
}
