import { useAsyncData, useNuxtApp } from '#imports'

import { getOrderlineTimeline } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import { getResolution } from '@backmarket/http-api/src/api-specs-resolution-engine/endpoints'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

import { SALES_CUSTOMER_CARE_SCOPE } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

/**
 * Explanation:
 *
 * When using async composables or functions, there is a risk of losing the Nuxt context after an `async/await` operation.
 * This occurs because the Vue.js Composition API and Nuxt composables rely on an implicit context that gets unset
 * after an async operation to prevent shared references in a global context.
 *
 * Example:
 *
 * async setup() {
 *   const instance = getCurrentInstance(); // Works
 *   await someAsyncOperation(); // Vue unsets the context
 *   getCurrentInstance(); // null
 * }
 *
 * To address this, we use the `useNuxtApp` and `runWithContext` methods to restore the context, similar to how <script setup> works.
 *
 * Additionally, Nuxt has an experimental feature called `asyncContext` that will automatically handle the context for us.
 * This code can be updated once this feature is no longer experimental.
 *
 * For more details, see:
 * - Nuxt Documentation on asyncContext: https://nuxt.com/docs/api/composables/async-context
 * - GitHub Issue Discussion: https://github.com/nuxt/nuxt/issues/14269#issuecomment-1397352832
 */

export function useReturnConfirmationData({
  resolutionId,
}: {
  resolutionId: string
}) {
  const nuxtApp = useNuxtApp()
  const { logHttpOnEvent } = useSalesCustomerCareLogger()

  return useAsyncData('return-confirmation-data', async () => {
    const resolutionPayload = await $httpFetch(getResolution, {
      pathParams: {
        resolutionId,
      },
      onEvent: (event, context) =>
        logHttpOnEvent({
          event,
          context,
          errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.returnConfirmationPage} Error when fetching associated resolution for resolutionId:${resolutionId}`,
        }),
    })

    const orderlineTimelinePayload = await nuxtApp.runWithContext(async () =>
      $httpFetch(getOrderlineTimeline, {
        pathParams: {
          orderlineId: resolutionPayload.resolution.problemContext.orderline.id,
        },
        onEvent: (event, context) =>
          logHttpOnEvent({
            event,
            context,
            errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.returnConfirmationPage} Error when fetching associated orderline timeline for orderlineId:${resolutionPayload.resolution.problemContext.orderline.id}`,
          }),
      }),
    )

    return {
      orderlineTimeline: orderlineTimelinePayload,
      resolution: resolutionPayload.resolution,
    }
  })
}
