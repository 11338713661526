<template>
  <ReturnConfirmationShell
    :current-page-title="i18n(translations.pageTitle)"
    data-test="repair-confirmation-page"
    illustration-url="/img/common/checkedAndCertified.svg"
    :resolution="props.resolution"
  >
    <template #subHeading> {{ i18n(translations.subHeading) }} </template>

    <template #heading> {{ i18n(translations.heading) }} </template>

    <template #description>
      <FormattedMessage :definition="translations.description">
        <template #duration>
          <span class="whitespace-nowrap">
            {{
              i18n(translations.expectedDuration, {
                duration: descriptionDuration,
              })
            }}
          </span>
        </template>
      </FormattedMessage>
    </template>

    <template #actions>
      <ReturnActions :resolution="props.resolution" />

      <GetMoreHelpAction :resolution-id="props.resolution.id" />
    </template>
  </ReturnConfirmationShell>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import GetMoreHelpAction from '../Actions/GetMoreHelpAction/GetMoreHelpAction.vue'
import ReturnActions from '../Actions/ReturnActions/ReturnActions.vue'
import ReturnConfirmationShell from '../ReturnConfirmationShell/ReturnConfirmationShell.vue'

import translations from './RepairConfirmation.translations'
import type { RepairConfirmationProps } from './RepairConfirmation.types'

const props = defineProps<RepairConfirmationProps>()

const i18n = useI18n()

const descriptionDuration = 3
</script>
