import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './RefundConfirmation.translations'

export type RefundConfirmationConfig = Partial<
  Record<
    StepType,
    {
      subHeading: string
      heading: string
      descriptions: Array<string>
      actions: {
        displayReturnActions: boolean
        displayGetMoreHelpAction: boolean
        displayGoToMyOrdersAction: boolean
      }
      footerActions: {
        displayReturnActions: boolean
      } | null
    }
  >
>

export const refundConfirmationConfig = (): RefundConfirmationConfig => {
  const i18n = useI18n()

  return {
    [ORDERLINE_TIMELINE_STEP_TYPE.delivery]: {
      subHeading: i18n(translations.subHeading),
      heading: i18n(translations.heading),
      descriptions: [i18n(translations.description)],
      actions: {
        displayReturnActions: true,
        displayGetMoreHelpAction: true,
        displayGoToMyOrdersAction: false,
      },
      footerActions: null,
    },

    [ORDERLINE_TIMELINE_STEP_TYPE.shipment]: {
      subHeading: i18n(translations.subHeading),
      heading: i18n(translations.variantHeading),
      descriptions: [
        i18n(translations.variantDescription),
        i18n(translations.variantShippedSubDescription),
      ],
      actions: {
        displayReturnActions: false,
        displayGetMoreHelpAction: true,
        displayGoToMyOrdersAction: true,
      },
      footerActions: {
        displayReturnActions: true,
      },
    },
    [ORDERLINE_TIMELINE_STEP_TYPE.validation]: {
      subHeading: i18n(translations.subHeading),
      heading: i18n(translations.variantHeading),
      descriptions: [
        i18n(translations.variantDescription),
        i18n(translations.variantPreparedSubDescription),
      ],
      actions: {
        displayReturnActions: false,
        displayGetMoreHelpAction: true,
        displayGoToMyOrdersAction: true,
      },
      footerActions: {
        displayReturnActions: true,
      },
    },
  }
}
