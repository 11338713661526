<template>
  <RevButton
    v-if="!tiny"
    data-qa="print-purchase-proof"
    data-test="print-purchase-proof"
    full-width="adaptive"
    target="_blank"
    :to="saleCertificateUrl"
    :variant
  >
    {{ i18n(translations.label) }}
  </RevButton>

  <RevButton
    v-else
    class="w-fit"
    data-qa="print-purchase-proof"
    data-test="print-purchase-proof"
    :icon="IconDownload"
    size="small"
    target="_blank"
    :to="saleCertificateUrl"
    :variant
  >
    {{ i18n(translations.label) }}
  </RevButton>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { IconDownload } from '@ds/icons/IconDownload'

import translations from './ProofOfPurchaseAction.translations'
import type { ProofOfPurchaseActionProps } from './ProofOfPurchaseAction.types'

const props = withDefaults(defineProps<ProofOfPurchaseActionProps>(), {
  tiny: false,
  variant: 'secondary',
})

const i18n = useI18n()

const saleCertificateUrl = {
  type: 'external',
  href: `bm/order/orderline/${props.orderPublicId}/sale-certificate`,
}
</script>
