export default {
  expectedDuration: {
    id: 'resolution_flow_return_confirmation_page_description_expected_durations',
    defaultMessage: `{duration, number} {duration, plural,
              one {business day}
              other {business days}
            }`,
  },
  description: {
    id: 'resolution_flow_return_confirmation_page_description_repair_or_replace_option',
    defaultMessage:
      'Once the seller receives your item, they’ll repair or replace it within {duration, html} and then send it back to you. It may take a few more days to arrive.',
  },
  heading: {
    id: 'resolution_flow_return_confirmation_page_title_repair',
    defaultMessage: 'All set for your repair!',
  },
  pageTitle: {
    id: 'resolution_flow_return_confirmation_page_breadcrumb_repair_request',
    defaultMessage: 'Repair request',
  },

  subHeading: {
    id: 'resolution_flow_return_confirmation_page_info_title_repair_requested',
    defaultMessage: 'Repair requested',
  },
}
