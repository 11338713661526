import { defineAsyncComponent } from 'vue'

import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import {
  type GetResolutionOptionsTypeEnum,
  RESOLUTION_OPTION_TYPE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'

export type ReturnConfirmationConfig = Partial<
  Record<
    GetResolutionOptionsTypeEnum,
    {
      allowedOrderlineTimelineStep: Array<Partial<StepType>>
      component: ReturnType<typeof defineAsyncComponent>
    }
  >
>

export const returnConfirmationPageConfig: ReturnConfirmationConfig = {
  [RESOLUTION_OPTION_TYPE_ENUM.refund]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
      ORDERLINE_TIMELINE_STEP_TYPE.validation,
    ],
    component: defineAsyncComponent(
      () => import('./components/RefundConfirmation/RefundConfirmation.vue'),
    ),
  },
  [RESOLUTION_OPTION_TYPE_ENUM.sellerRepair]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
    ],
    component: defineAsyncComponent(
      () => import('./components/RepairConfirmation/RepairConfirmation.vue'),
    ),
  },
  [RESOLUTION_OPTION_TYPE_ENUM.repairReplace]: {
    allowedOrderlineTimelineStep: [
      ORDERLINE_TIMELINE_STEP_TYPE.delivery,
      ORDERLINE_TIMELINE_STEP_TYPE.shipment,
    ],
    component: defineAsyncComponent(
      () =>
        import(
          './components/RepairReplaceConfirmation/RepairReplaceConfirmation.vue'
        ),
    ),
  },
}
